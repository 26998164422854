/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://w5lcbqebsjbm3erubuyhf4nzhu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-bihw4yuvgnh2noiotacuszbk4y",
    "aws_cognito_identity_pool_id": "eu-west-1:0ba38689-14ae-47c8-8b7f-2ca0b01b762a",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_yNPRyTmuo",
    "aws_user_pools_web_client_id": "2inbhmb90pd0pscebiqujaj658",
    "oauth": {
        "domain": "bridgegraphql5835b8a4-5835b8a4-prodfour.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://bridge.one.org/",
        "redirectSignOut": "http://localhost:3000/,https://bridge.one.org/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
